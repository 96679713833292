<template>
<div class="entrance container mt-5">
  <div class="row align-items-center">
    <div class="col-12 col-sm-12 md-6 col-lg-5 col-xl-5 colxxl-4">
                      <router-link to="/login" class="no_decor">
                    <div class="card">
                        <div class="card-header">
                            <span class="card_heading">Welcome back&#8205;  &#8205;  </span>
                            <button class="btn btn-light bttn_2">Come In</button>
                        </div>
                        <div class="card-body">
                            <ul class="card_list">
                                <div class="text item card-title">Your dashboard</div>
                                <ul class="card_list">
                                    <div class="list_tag">
                                        <img class="tick_svg" src="../assets/tick_grey.svg" />
                                        <div class="text item">Company Insights</div>
                                    </div>
                                    <div class="list_tag">
                                        <img class="tick_svg" src="../assets/tick_grey.svg" />
                                        <div class="text item">Data folders</div>
                                    </div>
                                    <div class="list_tag">
                                        <img class="tick_svg" src="../assets/tick_grey.svg" />
                                        <div class="text item">Projects</div>
                                    </div>
                                    <div class="list_tag">
                                        <img class="tick_svg" src="../assets/tick_grey.svg" />
                                        <div class="text item">Branding</div>
                                    </div>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </router-link>
    </div>
    <div class="col-12 col-sm-12 md-6 col-lg-5 col-xl-5 colxxl-4">
                <router-link to="/register" class="no_decor">
<div class="card">
                        <div class="card-header">
                            <span class="card_heading">Hello stranger </span>
                            <button class="btn button_signup">Sign Up</button>
                        </div>
                        <div class="card-body">
                                <ul class="card_list">
                                    <div class="text item card-title">Develop your business:</div>
                                    <ul class="card_list">

                                        <div class="list_tag">
                                            <img class="tick_svg" src="../assets/gradient_tick.svg" />
                                            <div class="text item">On Google</div>
                                        </div>
                                        <div class="list_tag">
                                            <img class="tick_svg" src="../assets/gradient_tick.svg" />
                                            <div class="text item">With Your Website</div>
                                        </div>
                                        <div class="list_tag">
                                            <img class="tick_svg" src="../assets/gradient_tick.svg" />
                                            <div class="text item">No Matter Your Scale</div>
                                        </div>
                                        <div class="list_tag">
                                            <img class="tick_svg coffee_cup" src="../assets/icon_coffee_cup.svg" />
                                            <div class="text item">By inviting us in for a coffee</div>
                                        </div>
                                    </ul>
                                </ul>
                        </div>
                    </div>
                </router-link>
                    </div>
  </div>
    <div class="layout gutter--lg">

        <div class="row justify--center">
            <div class="flex md6 lg5 xl4">

            </div>

            <div class="flex md6 lg5 xl4">


            </div>
        </div>
    </div>
</div>
</template>

<script>
// import { mapGetters } from "vuex";
import store from '../store'
import router from '../router';

    export default {
        beforeMount(){
            if(store.state.user){
                router.replace("/dashboard")
            }
        },
        data() {
            return {
            };
        },
        computed: {

        },
        methods: {
        }
    };
</script>

<style lang="scss" scoped>
a{
    text-decoration: none !important;
    color: rgb(41, 41, 41);
}
.entrance {
    text-decoration: none !important;
}
  .list_tag {
    height: 2rem;
    margin-bottom: 0.5rem;
  }

    .tick_svg {
    height: 100%;
    float: left;
    padding-right: 1.5rem;
  }

  .coffee_cup {
    padding-left: 0.2rem;
  }

  .item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

.btn{
    width: 40%;
          border-radius: 2rem;
    font-weight: 800 !important;
    font-size: 1rem !important;
    text-decoration: none !important;

  &.button_signup {
    background: linear-gradient(60deg, #bbff9c, #22f7f7c2) !important;
    &:hover {
    box-shadow: 2px 2px 5px #96fcf7,
                -2px -2px 5px #befd9e;
    }


  }
}


.bttn_2 {

background-color: #f3f3f3;
border: 2px solid rgb(163, 163, 163);}

.va-button__content{
  padding: 0 !important;
}

</style>